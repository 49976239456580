@import "@/assets/scss/main.scss";

.section {
  text-align: left;
  margin-bottom: 1rem;
  .section-title {
    color: $grey-7-mayday;
    text-transform: uppercase;
  }
}
.section-group {
  border: 1px solid $grey-4-mayday;
  border-radius: 5px;
  padding: 0.8rem;
}
.section-message {
  font-size: 12px;
}
.tags-container {
  max-height: 100px;
  overflow: auto;
}

.notification__reviewer {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  min-height: 20vh;
  background-color: $grey-1-mayday;

  :deep() p {
    font-size: 0.9rem !important;
    margin: 4px 0 !important;
  }

  :deep() li {
    margin-bottom: 6px !important;
  }
}
