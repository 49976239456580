@import "@/assets/scss/main.scss";

:deep() .modal-body {
  padding: 16px;
}

.header-card {
  padding: 12px;
  background-color: $grey-1-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .divider {
    content: '';
    height: 1px;
    width: 80%;
    background-color: $grey-4-mayday;
    margin-bottom: 0px;
  }
  .description {
    font-size: 12px;
    color: $grey-7-mayday;
  }
}

.templates {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  min-height: 216px;
  max-height: 216px;
  overflow: auto;
}

.empty-templates {
  padding: 8px;
  text-align: center;
}

.templates-search {
  width: 100%;
  padding-top: 8px;
  .search-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid $grey-2-mayday;
    padding: 8px;
    font-size: 14px;
    input {
      width: 100%;
      border: none;
      outline: none;
    }
  }
}
.template {
  cursor: pointer;

  display: flex;
  align-items: center;

  width: 100%;
  min-width: 0;
  background-color: transparent;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 4px;
  }

  .template-info {
    display: flex;
    min-width: 0;
    padding: 8px;
    width: 100%;
    .template-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px !important;
      height: 32px;
      margin-right: 12px;
      border-radius: 4px;

      .template-icon-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: 4px;
      }
      :deep() .logo-container {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
    }

    .template-label {
      min-width: 0;
      width: calc(100% - 44px);
      .name {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
      }

      .description {
        margin-top: 4px;
        font-size: 12px;
        color: $grey-6-mayday;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  gap: 8px;
}

.templates-view-empty {
  width: 400px;
  height: 216px;
  display: flex;
  flex-direction: column;
  padding: 0 42px;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .icon {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .description {
    font-size: 12px;
    color: $grey-7-mayday;
  }
}

.warning-icon {
  font-size: 14px;
}
