@import "@/assets/scss/main.scss";

.icon {
  height: 22px;
}
.green {
  color: $green-mayday;
}
.red {
  color: $red-mayday;
}
.suggestion-item {
  padding: 6px 16px;
  cursor: pointer;
  min-height: 40px;
}
.background-grey {
  background: $grey-1-mayday;
}
.marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
}
.bg-yellow {
  background: $yellow-mayday;
}
.bg-blue {
  background: $blue-mayday;
}
.bg-grey {
  background: $grey-5-mayday;
}
.bg-black {
  background: $grey-8-mayday;
}
.btn-enter {
  font-size: 8px;
  font-weight: bold;
  color: $grey-6-mayday;
  background: $grey-1-mayday;
  border: 0.5px solid $grey-4-mayday;
  border-radius: 2px;
  cursor: pointer;
}
.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.overflow {
  overflow: hidden;
  white-space: nowrap;
}
.label {
  font-size: 14px;
  font-weight: 500;
  color: $grey-9-mayday;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
}
.label-row {
  display: flex;
  align-items: center;
}

.full-labels {
  margin-top: 4px;
}

.explain-prompt {
  display: inline-flex;
  line-height: 0.1px;
}

.time-ago {
  white-space: nowrap;
}

.is-hidden-sticker {
  background-color: red;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  left: 29px;
  top: 9px;
}
.label-wrapper {
  max-width: calc(100% - 50px);
}
