@import "@/assets/scss/main.scss";

.toast__container {
  width: 300px;
  z-index: 1;
  animation: toast-open 0.4s ease-in-out;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  margin-top: 8px;
}

.toast__header {
  width: 100%;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  background-color: $blue-mayday;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.urgent {
    background-color: $red-6-mayday;
  }

  :deep() .time-ago {
    font-size: 8px;
    font-weight: 700;
    color: white;
  }
}

.toast__header-infos {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  gap: 8px;
}

.toast__title {
  display: flex;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &.opened {
      -webkit-line-clamp: unset;
    }
  }

  .toast__author {
    padding: 5px 2px 5px 4px;
    font-size: 10px;
    text-align: center;
    border-radius: 2px;
    margin-right: 4px;
  }
}

.toast__body-container {
  padding: 12px 16px;
  background-color: white;
  color: black;
  font-weight: 700;
  font-size: 12px;
  min-width: 0;
}

.toast__body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;
  font-size: 12px;
  font-weight: 500;
  color: $grey-8-mayday;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &.opened {
    max-height: 350px;
    padding: 8px 0;
    overflow: auto;
  }
}

.toast__labels {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  font-size: 9px;
  font-weight: 700;
  color: $grey-8-mayday;

  .toast__label {
    padding: 2px 4px;
    border-radius: 2px;
    background-color: rgba($blue-mayday, 0.2);
  }
}

.toast__footer {
  padding: 6px 10px;
  border-radius: 0 0 4px 4px;
  background-color: $grey-1-mayday;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.urgent {
    :deep() .el-button {
      background-color: $red-6-mayday;
      border-color: $red-6-mayday;
    }
  }
}

.pan-leave-active {
  z-index: 0;
  animation: toast-close 0.4s ease-in-out;
}

@keyframes toast-close {
  0% {
    height: auto;
    transform: translateY(0);
    z-index: -1;
  }
  100% {
    height: 0;
    margin-top: 0;
    transform: translateY(450px);
  }
}

@keyframes toast-open {
  0% {
    z-index: 0;
    max-height: 0;
    transform: translateY(450px);
  }
  99% {
    z-index: 0;
  }
  100% {
    z-index: 1;
    max-height: 130px;
    transform: translateY(0);
  }
}
