@import "@/assets/scss/main.scss";

.callout {
  background-color: rgba($red-1-mayday, 0.4);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  .icon {
    color: $red-5-mayday;
    font-size: 16px;
    height: 16px;
  }
}
.confirmation {
  font-size: 14px;
}
