@import "@/assets/scss/main.scss";

.alert {
  background: white;
  color: $grey-8-mayday;
  font-size: 1em;
  border-radius: 4px;
}

.alert-success .alert-inner--icon .fa {
  color: $blue-mayday !important;
}

.alert-danger .alert-inner--icon .fa {
  color: $red-mayday !important;
}

.alert-warning {
  border-color: $yellow-mayday;
}

.alert-warning .alert-inner--icon .fa {
  color: $yellow-mayday;
}
