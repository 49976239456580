@import "@/assets/scss/main.scss";

.download {
  display: none;
  color: $blue-mayday;
  width: 10%;
  height: 12px;
  align-items: center;
  justify-content: center;
}

.attachment:hover .download {
  display: flex;
}

.attachment {
  padding: 4px;
  width: calc(var(--attachment-width) - (8px / 3));
  height: 22px;
  background-color: $grey-2-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.simple-attachment:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.close {
  display: flex;
  width: 10%;
  height: 12px;
  &:hover {
    cursor: pointer;
  }
  .close-icon {
    width: 100%;
    height: 100%;
  }
  .icon-loading {
    color: $blue-mayday;
  }
}

.icon-error {
  color: $red-mayday;
  cursor: pointer;
}

.icon-wrapper {
  width: 10%;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachment-title {
  width: 90%;
  height: 100%;
}

.attachment-name {
  width: 90%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.attachment-size {
  white-space: nowrap;
  font-size: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}
