@import "@/assets/scss/main.scss";

.custom-checkbox .custom-control-label::before {
  border-radius: 2px !important;
  border: 2px solid $blue-mayday;
}

/* .custom-control-label {
  text-align: left;
} */
