@import "@/assets/scss/main.scss";

/* .search-tags-input{
  display: none;
} */

.sticky-top {
  z-index: 1 !important;
}

.modal-content {
  border-radius: 2px !important;
}

.el-collapse-item__content {
  padding-bottom: 8px !important;
}

.el-popconfirm__main {
  font-size: 12px !important;
}

.el-drawer__close-btn:focus {
  outline: none !important;
}

.dropdown-menu {
  border-radius: 3px !important;
}

.el-notification__content {
  text-align: left;
}

.dropdown-item:hover {
  background-color: $grey-1-mayday;
  border-radius: 2px;
}

.dropdown-item:active {
  background-color: $grey-1-mayday !important;
  color: $blue-mayday !important;
}

.el-popover {
  padding: 8px !important;
}

.el-popover:active,
.el-popover:focus,
.el-popover,
.el-popover:active:focus,
.el-popover__reference:active,
.el-popover__reference:focus,
.el-popover__reference,
.el-popover__reference:active:focus,
.el-tooltip:active,
.el-tooltip:focus,
.el-tooltip,
.el-tooltip:active:focus {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.previous-steps:hover {
  background-color: rgba($blue-mayday, 0.05);
  padding-left: 5px;
}

.is-light {
  border: 0px !important;
  background-color: white !important;
  box-shadow: $shadow-mayday;
  color: $grey-6-mayday;
}

.navbar-popover {
  padding: 0 !important;
}

.notification-popover-web {
  overflow: auto;
  padding: 0 !important;
  margin-right: 60px;
  text-align: left !important;
  border: none !important;
}

@media (max-width: 991px) {
  .notification-popover-web {
    overflow: auto;
    margin-right: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    top: 44px !important;
  }
}

.notification-popover-admin {
  overflow: auto;
  padding: 0 !important;
  left: 70px !important;
  text-align: left !important;
  bottom: calc(20vh - 83px) !important;
  transform: translate(0, 0) !important;
}

.modal-open {
  overflow: hidden !important;
}

.custom-notification {
  width: auto !important;
  max-width: 450px !important;
  padding-left: 10px !important;
  border-radius: 4px !important;
  border-top: 10px !important;
  border-bottom: 10px !important;
  .el-icon-info {
    color: $blue-mayday !important;
  }
  .el-notification__icon {
    margin-top: 5px;
  }
}

.custom-notification-info {
  border-left: 10px solid $blue-mayday !important;
}

.custom-notification-error {
  border-left: 10px solid $red-mayday !important;
}

.custom-popover {
  padding: 0px !important;
  margin: 0px;
}

.textarea-tooltip {
  white-space: pre-wrap !important;
  max-width: 50%;
}

.small-tooltip {
  white-space: pre-wrap !important;
  font-size: 12px !important;
  padding: 4px 6px !important;
}

.prompt-popover {
  padding: 0px !important;
  .el-popover__title {
    padding: 8px;
    margin-bottom: 0px !important;
    border-bottom: 1px solid $grey-4-mayday;
  }
}

.popover-container {
  max-height: 70vh !important;
  overflow-y: auto !important;
}

.mayday-editor__slash-menu .options-icon {
  top: 14px !important;
  font-size: 12px;
}

.mayday-editor__slash-menu
  .mayday-editor__slash-menu__items
  .mayday-editor__slash-menu__items__item-wrapper.is-disabled,
.mayday-editor__bubble-menu-text-selection
  .mayday-editor__bubble-menu-text-selection__commands-container
  .mayday-editor__bubble-menu-button-wrapper
  .mayday-editor__bubble-menu-button-wrapper__command-button.is-disabled {
  opacity: 0.5 !important;
}

// Hacky way to fix css of teleported tippy component from web componented notif modal
.dp--menu-wrapper {
  .dp__action_row {
    button {
      padding: 3px 4px;
      border-color: transparent !important;
    }
    .bg-gray-5\/0 {
      background-color: rgba(125, 137, 160, 0);
    }
    .bg-blue-5 {
      background-color: $blue-mayday;
    }
  }
}

.tippy-box {
  .panel {
    .footer {
      padding: 0;
      background-color: transparent;
    }
  }
}
