@import "@/assets/scss/main.scss";

.publish-modal {
  :deep() .modal-dialog {
    width: 320px;
  }

  :deep() .modal-content {
    border-radius: 8px !important;
  }

  :deep() .modal-body {
    padding: 8px 8px 0px 8px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.staged-version-modal-header {
  text-align: center;
  line-height: 18px;
}

.publish-modal-description {
  font-size: 14px;
}

.icon {
  color: $blue-mayday;
  width: 42px;
  height: 42px;
}

.w-divider {
  background-color: white;
}

.button-cancel {
  margin-top: 8px;
  margin-left: 0px !important;
}

.list-languages {
  padding-inline-start: 20px !important;
}
