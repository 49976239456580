@import "@/assets/scss/main.scss";

:deep() .modal-body {
  padding: 16px;
}

.header-card {
  padding: 12px;
  background-color: $grey-1-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .divider {
    content: '';
    height: 1px;
    width: 80%;
    background-color: $grey-4-mayday;
    margin-bottom: 0px;
  }
  .description {
    font-size: 12px;
    color: $grey-7-mayday;
  }
}

.template-inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 0px;
}

.template-input {
  font-size: 14px;
  input,
  textarea {
    outline: none;
    padding: 8px 8px;
    width: 100%;
    background-color: $grey-1-mayday;
    border: solid 1px $grey-3-mayday;
    border-radius: 4px;
    resize: none;
  }
  label {
    margin-bottom: 4px;
  }
}

.modal-footer {
  display: flex;
  align-items: right;
  padding: 0;
}

.radio-group {
  display: flex;
  width: 100%;
  gap: 16px;
}

.radio-wrapper {
  width: 100%;
  background-color: $grey-1-mayday;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
  border: solid 1px $grey-3-mayday;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border: solid 1px $blue-mayday;
  }
}

.radio {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 16px;
}
