@import "@/assets/scss/main.scss";

.section {
  text-align: left;
  margin-bottom: 1rem;
}
.section-title {
  color: $grey-7-mayday;
  text-transform: uppercase;
}

.notification__editor {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  min-height: 20vh;
}

.add-button {
  min-width: 57px;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue-mayday;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  &__disable {
    background-color: $blue-1-mayday;
    &:hover {
      cursor: not-allowed;
    }
  }
  &__enable:hover {
    cursor: pointer;
  }
}
