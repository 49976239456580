@import "@/assets/scss/main.scss";

.notifications {
  position: absolute;
  top: 0;
  right: 0;
  max-height: 100vh;
  height: fit-content;
  pointer-events: none;
}

.toast__wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  pointer-events: all;
  z-index: 2000;
  width: 330px;
  margin: 0 auto;
  padding: 0 20px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: auto;
}
