@import "@/assets/scss/main.scss";

.icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
}
.text-blue {
  color: $blue-mayday;
}
.text-black {
  color: $grey-8-mayday;
}
