//
// Badge floating
//


.btn {
    .badge-floating {
        position: absolute;
        top: -10%;
        transform: translateY(50%);
        border: 2px solid;
        right: 10px;

        &.badge:not(.badge-circle) {
            transform: translate(-20%, 50%);
        }
    }
}
