@import "@/assets/scss/main.scss";

.tooltip-container {
  min-width: 0;
  max-width: 100%;
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
