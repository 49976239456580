@import "@/assets/scss/main.scss";

.icon-fa {
  color: $grey-4-mayday;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
