@import "@/assets/scss/main.scss";

.toast-container {
  z-index: 3000;
  top: 14px;
  right: 20px;
  position: fixed;
  width: 420px;
  background-color: white;
  border-radius: 4px;
  border-left: 8px solid var(--main-color);
  box-shadow: $shadow-mayday;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  animation: toast-open 0.4s ease-in-out;
}

.toast-layout {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 24px 18px 18px 8px;
}

.toast-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  line-height: 14px;
}

.toast-icon {
  margin-bottom: 4px;
  color: var(--main-color);
}

.toast-title {
  font-weight: 700;
  font-size: 16px;
}

.toast-body {
  font-size: 12px;
  color: $grey-6-mayday;
}

.toast-close {
  position: absolute;
  top: 6px;
  right: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 2px;
  color: var(--main-color);

  &:hover {
    background-color: var(--hover-color);
  }
}

.toast-cta {
  align-self: flex-end;
}

.pan-leave-active {
  animation: toast-close 0.4s ease-in-out;
}

@keyframes toast-close {
  0% {
    height: auto;
    transform: translateX(0);
  }
  100% {
    height: 0;
    margin-top: 0;
    transform: translateX(450px);
  }
}

@keyframes toast-open {
  0% {
    max-height: 0;
    transform: translateX(450px);
  }
  100% {
    max-height: 130px;
    transform: translateX(0);
  }
}
