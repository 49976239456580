@import "@/assets/scss/main.scss";

/* Base Modal Styles */
.base-modal-container {
  display: flex;
  border-radius: 8px;
  background: white;
  box-shadow: $shadow-lg-mayday;
}

.base-modal-sidebar {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $grey-3-mayday;
}

.base-modal-sidebar-placeholder {
  flex: 1;
  background-color: rgba(0, 122, 255, 0.1);
  border-radius: 4px;
  width: 172px;
}

.base-modal-subtitle {
  font-size: 1rem;
  color: $grey-9-mayday;
}

.base-modal-content {
  padding: 16px;
  padding-bottom: 12px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.base-modal-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12px * var(--tw-space-y-reverse));
}

.base-modal-content-header-padding {
  padding-top: 16px;
}

.base-modal-header > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

.base-modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.base-modal-title {
  font-size: 20px;
  line-height: 24px;
  color: $grey-9-mayday;
}

.base-modal-description {
  font-size: 12px;
  line-height: 16px;
  color: $grey-7-mayday;
}

.base-modal-body-placeholder {
  background-color: rgba(0, 122, 255, 0.1);
  border-radius: 4px;
}

.base-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Size Classes */
.base-modal-w-sm {
  width: 400px;
}

.base-modal-w-md {
  width: 600px;
}

.base-modal-w-lg {
  width: 1000px;
}

.base-modal-h-sm {
  height: 264px;
}

.base-modal-h-md {
  height: 364px;
}

.base-modal-h-lg {
  height: 564px;
}
