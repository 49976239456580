@import "@/assets/scss/main.scss";

.base-dialog-container {
  display: flex;
  width: 320px;
  flex-direction: column;
  border-radius: 8px;
  background-color: white;
  box-shadow: $shadow-sm-mayday;
  overflow: hidden;
}

.base-dialog-header {
  height: 96px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 16px;
}
.base-dialog-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}
.base-dialog-icon {
  font-size: 42px;
}

.base-dialog-content {
  padding: 24px;
  padding-bottom: 20px;
  width: 100%;
  text-align: center;
  color: $grey-9-mayday;
}

.base-dialog-content-with-header {
  padding-top: 18px;
}
.base-dialog-content-no-header {
  padding-top: 8px;
}
.base-dialog-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.base-dialog-description {
  font-size: 14px;
  line-height: 20px;
}

.base-dialog-message {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.base-dialog-body {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
}

.base-dialog-footer {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.base-dialog-info-color {
  color: $blue-mayday;
}
.base-dialog-error-color {
  color: $red-6-mayday;
}
