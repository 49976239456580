@import "@/assets/scss/main.scss";

.notification-step-item {
  height: 10px;
  width: 10px;
  background-color: $grey-5-mayday;
  border-radius: 50%;
  &:not(:last-child) {
    margin-right: 5px;
  }
}
.notification-step-item-selected {
  background-color: $blue-mayday;
}
.icon {
  width: 24px;
  height: 24px;
}
