@import "@/assets/scss/main.scss";

.attachments {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}
.title {
  font-size: 12px;
  font-weight: 700;
}
.custom-border-red {
  border-bottom: 2px solid $red-mayday;
}

.disable-file-upload-message {
  font-size: 9px;
  color: $red-mayday;
  text-align: left;
}
