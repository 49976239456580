@import "@/assets/scss/main.scss";

.notification__title-content {
  font-weight: 600;
  padding: 0 4px;
  border-radius: 2px;
  cursor: pointer;

  img {
    margin-bottom: 3px;
    margin-right: 3px;
    height: 16px;
    width: 10px;
  }

  //underline animation
  background-image: linear-gradient($grey-3-mayday, $grey-3-mayday);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position-x: right;

  transition: background-size 300ms;
  &:hover {
    // text-decoration: underline;
    background-size: 100% 100%;
    background-position-x: left;
  }
}
.disabled {
  cursor: not-allowed;
  span {
    pointer-events: none;
  }
}

.case-icon {
  margin-right: 4px;
  color: $grey-5-mayday;
}
