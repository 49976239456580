@import "@/assets/scss/main.scss";

.form-group {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.form-control-label {
  margin-bottom: 0;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.form-control {
  color: $grey-8-mayday;
}
.form-control.is-valid,
.form-control.is-invalid {
  border: none;
}

.main-input {
  display: flex;
  border: none;
}

.show-or-hide-button,
.show-or-hide-button:focus {
  background-color: transparent;
  border: none;
  outline: none;
}

.form-control,
.input-group-text {
  border-radius: 2px;
}

.has-success:after,
.has-danger:after {
  display: none;
}

.has-success .input-group-prepend .input-group-text .fa {
  color: rgba($blue-mayday, 0.4);
}

.has-danger .input-group-prepend .input-group-text .fa {
  color: rgba($red-mayday, 0.4);
}

.info {
  display: none;
  font-size: 0.7em;
  margin-left: 0.5em;
  cursor: pointer;
}

.has-danger .info {
  display: block;
  color: $red-mayday;
}
