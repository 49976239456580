@import "@/assets/scss/main.scss";

.status {
  display: inline-block;
}

.status-text {
  font-size: 12px;
  color: $green-mayday;
}

.icon-active {
  max-width: 10px;
}

.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

p {
  color: black;
}

.integration-card {
  :deep() .card-body {
    padding: 10px;
  }
}

.integration-card {
  margin-bottom: 25px;
  border: 0px;
}

.integration-card:hover {
  background-color: $grey-1-mayday;
  transition-duration: 0.5s;
}
