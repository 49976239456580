@import "@/assets/scss/main.scss";

.create-notification-modal {
  :deep() .modal-body {
    height: 90vh;
    max-height: 90vh;
    padding: 0;
    overflow: auto;
  }

  :deep() .modal-content {
    border-radius: 4px !important;
  }

  :deep() .modal-dialog {
    max-width: 900px !important;
  }
}
