@import "@/assets/scss/main.scss";

.new-feature {
  border-radius: 10px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  font-size: 12px;
  line-height: 16px;
  padding: 0 8px;
}
