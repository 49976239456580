@import "@/assets/scss/main.scss";

.tags-input__wrapper {
  display: inline-block;
}

.form-control {
  margin-top: 10px;
  width: 245px;
}

.tag-icon {
  margin-bottom: 2px;
  margin-left: 5px;
}
