@import "@/assets/scss/main.scss";

.ai-badge {
  background-color: $purple-5-mayday;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  font-weight: 500;
  gap: 2px;
  padding: 0 4px;

  &.small {
    height: 14px;
    font-size: 8px;
    border-radius: 4px;
  }

  &.medium {
    height: 18px;
    font-size: 10px;
    border-radius: 4px;
    font-weight: 700;
  }
  &.large {
    height: 24px;
    font-size: 14px;
    border-radius: 4px;
    font-weight: 500;
  }
}
