@import "@/assets/scss/main.scss";

.content-publication-policy-modal {
  .modal-title {
    width: 100%;
    border-bottom: 1px solid $grey-4-mayday;
  }

  :deep() .modal-body {
    max-height: 80vh;
    overflow: auto;
    .selector {
      .el-input__prefix {
        display: flex;
        align-items: center;
        margin-left: 6px;
      }
    }
  }

  .modal-footer {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  :deep() .publication-switch-input.is-checked :deep() .el-switch__core {
    background-color: $blue-mayday;
    &:after {
      color: $blue-mayday;
    }
  }

  :deep() .el-switch__core:after {
    // content: '|';
    line-height: 1.2em;
    color: $grey-5-mayday;
    font-weight: bold;
    font-size: 0.8em;
    width: 14px;
    height: 14px;
    top: 2px;
  }
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.footer-row {
  justify-content: flex-end;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}

.publication-switch {
  background-color: $grey-1-mayday;
  border-radius: 4px;
  border: 1px solid $grey-4-mayday;
}

.date-picker {
  &:deep() .el-input__prefix {
    color: rgba($blue-mayday, 0.4);
  }
}
