@import "@/assets/scss/main.scss";

.base-callout {
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 16px;

  &.warning {
    background-color: rgb($yellow-mayday, 0.1);
  }

  &.danger {
    background-color: $grey-1-mayday;

    .base-callout-icon {
      color: $red-mayday;
    }
  }

  &-icon {
    margin-top: 2px;
    margin-right: 8px;
  }
}
