@import "@/assets/scss/main.scss";

.wrapper {
  min-width: 0;
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}
.temp {
  position: fixed;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}
.truncate {
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
