@import "@/assets/scss/main.scss";

.icon {
  width: 32px;
  height: 32px;
  color: $grey-5-mayday;
}
.header-sublabel {
  color: $red-mayday;
}
.delete-container {
  margin-top: 24px;
  padding: 16px;
  border: 1px solid $red-mayday;
  border-radius: 4px;
  text-align: center;
}
.delete-case-modal {
  :deep() .modal-body {
    max-height: 65vh;
    overflow: auto;
  }
  :deep() .modal-footer {
    justify-content: center;
    padding-top: 0;
  }
}
