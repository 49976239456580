@import "@/assets/scss/main.scss";

.atg-manager {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 11;
  max-height: 100vh;
  transition: all 0.3s ease-in-out;
  padding-top: 8px;

  &.transitioning {
    padding-bottom: 200px;
  }
}
.atg-manager__transition-group {
  transition: all 0.3s ease-in-out;
}
.atg-manager__transition-group > * {
  margin-bottom: 8px;
}

.atg-pan-move,
.atg-pan-enter-active,
.atg-pan-leave-active {
  transition: all 0.3s ease-in-out;
}

.atg-pan-enter,
.atg-pan-leave-to {
  transform: translateX(320px) !important;
  opacity: 0 !important;
}

.atg-pan-leave-active {
  position: absolute;
}
