@import "@/assets/scss/main.scss";

.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-xxl {
  min-width: 90vw;
  max-height: 80vh;
}

.modal-xxl {
  :deep() .modal-body {
    max-height: 90vh;
    overflow: auto;
    overflow-x: hidden;
  }
}

.modal-xxl {
  :deep() .mayday-table {
    max-height: 60vh;
    overflow: auto;
    overflow-x: hidden;
  }
}
